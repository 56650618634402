export default {
    index: {
        en: '/',
        nl: '/',
    },
    'over-ons': {
        en: '/about-us/',
        nl: '/over-ons/',
    },
    ...(process.env.DEFAULT_LOCALE === 'nl' && {
        trophy: {
            nl: '/beker/',
        },
    }),
    certificeringen: {
        en: '/certifications/',
        nl: '/certificeringen/',
    },
    contact: {
        en: '/contact/',
        nl: '/contact/',
    },
    ...(process.env.DEFAULT_LOCALE === 'nl' && {
        'services/index': {
            nl: '/diensten/',
        },
        'services/_slug': {
            nl: '/diensten/:slug/',
        },
    }),
    ...(process.env.DEFAULT_LOCALE === 'nl' && {
        duck: {
            nl: '/eenden/',
        },
    }),
    ...(process.env.DEFAULT_LOCALE === 'nl' && {
        kyc: {
            nl: '/kyc-monitoring/',
        },
    }),
    security: {
        en: '/security/',
        nl: '/security/',
    },
    snowglobe: {
        en: '/snowglobe/',
        nl: '/snowglobe/',
    },
    privacy: {
        en: '/privacy/',
        nl: '/privacy/',
    },
    ...(process.env.DEFAULT_LOCALE === 'nl' && {
        facilion: {
            nl: '/facilion/',
        },
    }),
    'research/tuuring': {
        en: '/research/tuuring/',
        nl: '/research/tuuring/',
    },
    'research/erasmus-mc': {
        en: '/research/erasmus-mc/',
        nl: '/research/erasmus-mc/',
    },
    'research/fagron': {
        en: '/research/fagron/',
        nl: '/research/fagron/',
    },
    'research/dnzb': {
        en: '/research/dnzb/',
        nl: '/research/dnzb/',
    },
    'research/nines': {
        en: '/research/nines/',
        nl: '/research/nines/',
    },
    'werk/index': {
        en: '/work/',
        nl: '/werk/',
    },
    ...(process.env.DEFAULT_LOCALE === 'nl' && {
        'werk/heerlijk': {
            nl: '/werk/heerlijk/',
        },
    }),
    'werk/jump-the-q': {
        en: '/work/jump-the-q/',
        nl: '/werk/jump-the-q/',
    },
    'werk/bobbys': {
        en: '/work/bobbys/',
        nl: '/werk/bobbys/',
    },
    'werk/fagron': {
        en: '/work/fagron/',
        nl: '/werk/fagron/',
    },
    'werk/nines': {
        en: '/work/nines/',
        nl: '/werk/nines/',
    },
    'werk/rembrandt': {
        en: '/work/rembrandt/',
        nl: '/werk/rembrandt/',
    },
    ...(process.env.DEFAULT_LOCALE === 'nl' && {
        'werk/recirculo': {
            nl: '/werk/recirculo/',
        },
    }),
    'werk/cbre': {
        en: '/work/cbre/',
        nl: '/werk/cbre/',
    },
    'werk/sonnema-1860': {
        en: '/work/sonnema-1860/',
        nl: '/werk/sonnema-1860/',
    },
    ...(process.env.DEFAULT_LOCALE === 'nl' && {
        'werk/student-doctors': {
            nl: '/werk/student-doctors/',
        },
    }),
    'werk/dnzb': {
        en: '/work/dnzb/',
        nl: '/werk/dnzb/',
    },
    'werk/erasmus-mc-intranet': {
        en: '/work/erasmus-mc-intranet/',
        nl: '/werk/erasmus-mc-intranet/',
    },
    'werk/belmond': {
        en: '/work/belmond/',
        nl: '/werk/belmond/',
    },
    'werk/ferring': {
        en: '/work/ferring/',
        nl: '/werk/ferring/',
    },
};
